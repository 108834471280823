<template>
  <main className="py-5 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 min-h-screen">
    <PageHeader title="Nieuw item" />

    <div class="mt-10 sm:mt-0" v-if="!loading">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">General information</h3>
            <p class="mt-1 text-sm text-gray-600">
              Here you can provide the information needed for the item.
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          
          <form @submit.prevent="handleSave" method="POST">
            <div class="shadow overflow-hidden sm:rounded-md">
              <div class="px-4 py-5 bg-white sm:p-6">
                <div class="grid grid-cols-6 gap-6">
                  
                  <div class="col-span-6 sm:col-span-3">
                    <label for="title" class="block text-sm font-medium text-gray-700">Title</label>
                    <input type="text" name="title" id="title" autocomplete="title" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="job.title">
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label for="last_name" class="block text-sm font-medium text-gray-700">Meta title</label>
                    <input type="text" name="last_name" id="last_name" autocomplete="family-name" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="job.meta_title">
                  </div>

                  <div class="col-span-6 sm:col-span-6">
                    <label for="email_address" class="block text-sm font-medium text-gray-700">Meta description</label>
                    <input type="text" name="email_address" id="email_address" autocomplete="email" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="job.meta_description">
                  </div>
                  
                  <div class="col-span-6 sm:col-span-6">
                    <label for="subtitle" class="block text-sm font-medium text-gray-700">Subtitle</label>
                    <input type="text" name="subtitle" id="subtitle" autocomplete="subtitle" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="job.subtitle">
                  </div>
                  
                </div>

              </div>
              
              <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500" @click="saveItem">
                  Next
                </button>
              </div>
            </div>
          </form>

        </div>
      </div>
    </div>

  </main>
</template>

<script>
import JobService from '../../../services/admin/jobs/service';

export default {
  name: 'Job',
  data() {
    return {
      job: {
        id: null,
        title: "",
        subtitle: "",
        meta_title: "",
        meta_description: ""

      },
      submitted: false
    }
  },
  methods: {
    saveItem() {
      console.log('saving')
      

      JobService.create(this.job)
        .then(response => {
          this.job.id = response.data.id;
          console.log(response.data);
          this.submitted = true;
          this.$router.push(`/jobs/${this.job.id}`);
        })
        .catch(e => {
          console.log(e);
        });
    },
    
    newItem() {
      this.submitted = false;
      this.homepage_item = {};
    }
  }
}
</script>